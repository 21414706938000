<template>
  <div class="w1092 pt60">
    <div class="board">
      <ul class="boardwrt">
        <li class="w160">{{$t('front.board.type')}}</li>
        <li class="w100w">
          <select v-model="model.type">
            <template v-for="type in typeList" v-bind:key="type">
              <option :value="type">{{$t('front.qnaTypeCategory.' + type)}}</option>
            </template>
          </select>
        </li>
      </ul>
      <ul class="boardwrt">
        <li class="w160">{{$t('front.board.title')}}</li>
        <li class="w100w"><input type="" :placeholder="$t('front.board.emptyTitle')" v-model="model.title"/></li>
      </ul>
      <ul class="boardwrt">
        <li class="w160">{{$t('front.search.content')}}</li>
        <li class="w100w"><textarea
          :placeholder="$t('front.board.commentPlaceholder')" v-model="model.content"></textarea></li>
      </ul>
    </div>

    <div class="boardbtn">
      <ui-button :className="'grsbtn mr5'" :text="$t('front.board.apply')" @click="onClickSubmit"/>
      <ui-button :className="'grsbtn'" :text="$t('front.board.cancel')" @click="onClickCancel"/>
    </div>

  </div>
</template>

<script>
import UiButton from '@/components/ui/UiButton'

export default {
  name: 'qnaWrite',
  components: {
    UiButton
  },
  data () {
    return {
      categoryList: [
        'livecasino',
        'slot',
        'sports',
        'esports',
        'minigame',
        'virtualgame',
        'cash',
        'betting',
        'etc'
      ],
      typeList: [
        'bankaccount',
        'etc'
      ],
      model: {
        category: '',
        type: 'etc',
        title: '',
        content: ''
      }
    }
  },
  created () {
  },
  methods: {
    onClickCancel () {
      this.$router.go(-1)
    },
    onClickSubmit () {
      if (!this.model.title) {
        this.onAlert('warningart', 'front.board.emptyTitle')
        return false
      }

      if (!this.model.content) {
        this.onAlert('warningart', 'front.board.emptyContent')
        return false
      }

      const params = {
        title: this.model.title,
        content: this.model.content,
        boardType: 'faq',
        category: this.model.category,
        faqType: this.model.type
      }
      if (this.boardIdx) {
        params.boardIdx = this.boardIdx
      }
      this.setSaveBoard(params)
    }
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
<style scoped>
.board {border: 1px solid #2f3545;}
</style>
